<template>
  <div class="global-stats-container row">
    <vs-card>
      <template slot="header">
        <div class="card-header">
          <h4 class="card-title">Financiamiento y Generación Solar</h4>
        </div>
      </template>

      <div class="xv-row flex">
        <statistics-card-line
            hideChart
            icon-right
            class="mb-base m-2"
            icon="DollarSignIcon"
            :statistic="financedAmount"
            color="success"
            statisticTitle="Monto financiado (MXN)"></statistics-card-line>

        <statistics-card-line
            hideChart
            icon-right
            class="mb-base m-2"
            icon="DollarSignIcon"
            :statistic="amountToFinance"
            color="success"
            statisticTitle="Monto por financiar (MXN)"></statistics-card-line>

        <statistics-card-line
            hideChart
            icon-right
            class="mb-base m-2"
            icon="DollarSignIcon"
            :statistic="amountToFinanceApproved"
            color="success"
            statisticTitle="Monto aprobado a financiar (MXN)"></statistics-card-line>

        <statistics-card-line
            hideChart
            icon-right
            class="mb-base m-2"
            icon="ZapIcon"
            :statistic="energyGeneration"
            color="warning"
            statisticTitle="Generación solar gradual (MW/h)"></statistics-card-line>

      </div>
    </vs-card>
  </div>
</template>

<script>
import StatisticsCardLine from "../../../../../vx-components/statistics-cards/StatisticsCardLine";
export default {
  name: "FinanceGenerationSolarStat",
  components: {StatisticsCardLine},
  props: {
    reportDate: {
      type: String,
      required: true
    },
    financedAmount: {
      type: [String, Number],
      required: true
    },
    energyGeneration: {
      type: [String, Number],
      required: true
    },
    amountToFinance: {
      type: [String, Number],
      required: true
    },
    amountToFinanceApproved: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style scoped>
.card-header {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
}
</style>
