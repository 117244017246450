<template>
  <div class="global-stats-container row">
    <vs-card>
      <template slot="header">
        <div class="card-header">
          <h4 class="card-title">Tasa de conversión</h4>
        </div>
      </template>

      <div class="xv-row flex">

        <statistics-card-line
            hideChart
            icon-right
            class="mb-base m-2"
            icon="UsersIcon"
            :statistic="leads"
            statisticTitle="Prospectos"></statistics-card-line>

        <statistics-card-line
            hideChart
            icon-right
            class="mb-base m-2"
            icon="ThumbsUpIcon"
            :statistic="projectInstalled"
            text-color="success"
            color="success"
            statisticTitle="Proyectos instalados"></statistics-card-line>

        <statistics-card-line
            hideChart
            icon-right
            class="mb-base m-2"
            icon="PercentIcon"
            :statistic="conversionRate"
            text-color="danger"
            color="danger"
            statisticTitle="Tasa de conversión"></statistics-card-line>
      </div>
    </vs-card>
  </div>
</template>

<script>
import StatisticsCardLine from "../../../../../vx-components/statistics-cards/StatisticsCardLine";

export default {
  name: "LeadStat",
  components: {StatisticsCardLine},
  props: {
    reportDate: {
      type: String,
      required: true
    },
    leads: {
      type: [String, Number],
      required: true
    },
    projectInstalled: {
      type: [String, Number],
      required: true
    },
    conversionRate: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style scoped>
.card-header {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
}
</style>
