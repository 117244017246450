<template>
    <div id="kof-home">
        <div class="vx-row">
            <!-- OVERLAY CARD -->
            <div class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                    ¡Bienvenid@ a la <span>Red</span>!
                    </h1>
                </vx-card>
            </div>

            <div class="vx-col w-full">
              <dashboard></dashboard>
            </div>
        </div>
    </div>
</template>

<script>
import Dashboard from "./components/analytics/Dashboard";
export default {
    components: {
      Dashboard,
    }
}
</script>

<style>
</style>