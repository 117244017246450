<template>
  <div>
    <lead-stat
        :report-date="report.reportDate"
        :leads="parseInt(report.leads)"
        :project-installed="parseInt(report.projectInstalled)"
        :conversion-rate="parsePercentage(report.conversionRate)"
        class="m-2"/>

    <finance-generation-solar-stat
        :report-date="report.reportDate"
        :financed-amount="parseK(report.financedAmount)"
        :amount-to-finance="parseK(report.amountToFinance)"
        :amount-to-finance-approved="parseK(report.amountToFinanceApproved)"
        :energy-generation="parseDecimal(report.energyGeneration)"
        class="m-2"/>

    <impact-data-stat
        :report-date="report.reportDate"
        :environmental-impact-gradual="parseDecimal(report.environmentalImpactGradual)"
        :economic-impact-gradual="parseK(report.economicImpactGradual)"
        :economic-impact-projected="parseK(report.economicImpactProjected)"
        :environmental-impact-projected="parseDecimal(report.environmentalImpactProjected)"
        class="m-2"/>

    <p class="font-small-2 ml-2">Nota: Información al {{ report.reportDate | humanize }}</p>
  </div>
</template>

<script>

import ImpactDataStat from "./ImpactDataStat";
import LeadStat from "./LeadStat";
import FinanceGenerationSolarStat from "./FinanceGenerationSolarStat";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import currencyHelper from "../../../../../components/mixins/currencyHelper";

export default {
  name: "Dashboard",
  components: {FinanceGenerationSolarStat, LeadStat, ImpactDataStat, Datepicker},
  data: () => ({
    statDate: null,
    statData: null,
    report: {
      reportDate: '',
      leads: 0,
      projectInstalled: 0,
      conversionRate: 0,
      financedAmount: 0,
      amountToFinance: 0,
      amountToFinanceApproved: 0,
      energyGeneration: 0,
      environmentalImpactProjected: 0,
      environmentalImpactGradual: 0,
      economicImpactGradual: 0,
      economicImpactProjected: 0
    }
  }),
  filters: {
    humanize(date) {
      return moment(date).locale('es').format("DD [de] MMMM [del] Y")
    }
  },
  async mounted() {
    let metricId = 'bdbbe579-a79e-4295-8f75-b6f7f4c0cef1';
    let programId = this.currentProgramData.id;
    this.statDate = moment()
        .subtract(1, 'day')
        .format("yyyy-MM-DD");

    const {data} = await axios.post(`/api/analytics/pgm/global/${metricId}`, {
      "report_date": this.statDate,
      "program_id": programId.toString()
    })

    this.report.reportDate = this.statDate;
    if (data.length > 0) {
      this.statData = data;
      this.report.leads = data[0].leads;
      this.report.projectInstalled = data[0].project_installed;
      this.report.conversionRate = data[0].conversion_rate;
      this.report.financedAmount = data[0].financed_amount;
      this.report.amountToFinance = data[0].amount_to_finance;
      this.report.amountToFinanceApproved = data[0].amount_to_finance_approved;
      this.report.energyGeneration = data[0].energy_generation;

      this.report.environmentalImpactGradual = data[0].gradual_impacts.enviromental;
      this.report.economicImpactGradual = data[0].gradual_impacts.economic;
      this.report.environmentalImpactProjected = data[0].environmental_impact;
      this.report.economicImpactProjected = data[0].economic_impact;
    }
  },
  methods: {
    parseDecimal(val) {
      return Intl.NumberFormat('es-mx').format(val.toFixed(2))
    },
    parsePercentage(decimal) {
      let roundedValue = parseFloat(decimal)
      return (roundedValue * 100).toFixed(2) + '%'
    },
    parseInt(val) {
      return Intl.NumberFormat('es-mx').format(val.toFixed(0))
    },
    parseK(value) {
      if (value === 0) {
        return value.toString();
      }
      if (value >= 1000 && value < 1000000) {
        return (value / 1000).toFixed(1).toString() + 'K';
      }
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1).toString() + 'M';
      }
    }
  }
}
</script>

<style scoped>

</style>
