<template>
  <div class="global-stats-container row">
    <vs-card>
      <template slot="header">
        <div class="card-header">
          <h4 class="card-title">Datos de impacto</h4>
        </div>
      </template>

      <div class="xv-row flex">
        <div class="xv-col w-full mt-5">
          <h4 class="ml-4">Proyectado a 25 años</h4>
          <div class="flex">
            <statistics-card-line
                hideChart
                icon-right
                class="mb-base m-2"
                icon="SunIcon"
                :statistic="environmentalImpactProjected"
                statisticTitle="Ambiental (Ton/CO2)"></statistics-card-line>

            <statistics-card-line
                hideChart
                icon-right
                class="mb-base m-2"
                icon="DollarSignIcon"
                :statistic="economicImpactProjected"
                text-color="success"
                color="success"
                statisticTitle="Economico (MXN)"></statistics-card-line>
          </div>
        </div>
        <div style="margin: 0px 20px; display: flex;">
          <!--<div style="width: 5px; height: 145px; background: rgb(242 109 97);"></div>-->
        </div>
        <div class="xv-col w-1/2 mt-5">
          <h4 class="ml-4">Gradual</h4>
          <div class="flex">
            <statistics-card-line
                hideChart
                icon-right
                class="mb-base m-2"
                icon="SunIcon"
                :statistic="environmentalImpactGradual"
                statisticTitle="Ambiental (Ton/CO2)"></statistics-card-line>

            <statistics-card-line
                hideChart
                icon-right
                class="mb-base m-2"
                icon="DollarSignIcon"
                :statistic="economicImpactGradual"
                text-color="success"
                color="success"
                statisticTitle="Económico (MXN)"></statistics-card-line>
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import StatisticsCardLine from "../../../../../vx-components/statistics-cards/StatisticsCardLine";

export default {
  name: "ImpactDataStat",
  components: {StatisticsCardLine},
  props: {
    reportDate: {
      type: String,
      required: true
    },
    environmentalImpactGradual: {
      type: [String, Number],
      required:true
    },
    economicImpactGradual: {
      type: [String, Number],
      required:true
    },
    environmentalImpactProjected: {
      type: [String, Number],
      required:true
    },
    economicImpactProjected: {
      type: [String, Number],
      required:true
    }
  }
}
</script>

<style scoped>
.card-header {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
}
</style>
